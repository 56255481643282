@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  padding-top: 12rem;
  min-height: 100%;
  background-image: url("../../images/grey_background.png");
  background-size: contain;
}
.main {
  min-height: 100vh;
}

.mainContainer {
  width: calc(100% - 5.3%);
  margin-left: auto;
  margin-right: 0;
}

.contentBox {
  width: 80%;
  margin: auto;
}
.main-header {
  width: 10;
  padding-top: 70px;
}
.contentBox h1 {
  font-size: 70px;
  font-weight: medium;
  margin-bottom: 30px;
  color: black;
  font-family: "65medium", sans-serif;
}
.items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 100px;
}
.item {
  width: 31%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.item a{
  font-size: 10px;
  color: black;
  text-decoration: none;
  font-weight: 700;
}
.item h6 {
  font-size: 10px;
}
.item ul li {
  font-size: 26px;
  list-style: none;
}

.brochure a {
  font-size: 21px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ACACA9;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: white;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  
  .body {
    padding-top: 8rem;
  }
  .main{
    height: auto;
  }
  .container {
    width: 95%;
  }
  .contentBox h1 {
    font-size: 20px;
    font-weight: medium;
    margin-bottom: 0px;
  }
  .items {
    flex-direction: column;
    margin-top: 50px;
  }

  .item {
    width: 100%;
  }
  .item ul li {
    font-size: 18px;
    list-style: none;
  }
  .brochure {
    margin-top: 0;
  }
  .brochure a {
    font-size: 18px;
    font-weight: bold;
    color: black;
    text-decoration: none;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

  /* END OF FOOTER */
}
