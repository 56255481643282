@font-face {
  font-family: "67condensed";
  src: url(../../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../../Fonts/45-light.otf);
}

.firstCarouselContainer {
  /* height:100rem; */
  width: 80%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.main-carousel-container {
  width: 85%;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
.background-image-block {
  height: 78vh;
  width: 100%;
  background-size: cover;
  position: absolute;
  /* left: 10%;  */
  z-index: -1;
}
.myContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.loadingContainer {
  height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myBtn {
  background-color: transparent;
  border: 1px solid black;
  padding: 8px;
  border-radius: 1rem;
  margin-top: 2rem;
}
.myBtn a {
  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: black;
}
.myBtn a .btn_icon {
  font-size: 15px;
  font-weight: 500;
}
.description-block-container {
  z-index: 3;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.description-block h1 {
  font-size: 8.6vh;
  font-family: "65medium", sans-serif;
  text-transform: uppercase;
  transition: all ease-in-out 0.2s;
  height: 19.9vh;
  width: 30vw;
  display: flex;
  align-items: flex-end;
  /* line-height: 1.2; */
}
p.description {
  max-height: calc(2.3vh * 3);
  line-height: 2.3vh;
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
  font-size: 1.9vh;
  font-family: "65medium", sans-serif;
  width: 40%;
  margin: 30px 0 0 0px;
  font-weight: 400;
  color: black;
  opacity: 0.7;
  position: relative;
  left: 0%;
}

.slideNavContainer {
  width: 100%;
}
.firstCarousel .flickity-page-dots {
  counter-reset: a;
  position: absolute;
  z-index: 5;
  width: auto;
  height: max-content;
  width: fit-content !important;
  top: 40%;
  left: 96%;
  display: flex;
  flex-direction: column;
}
.firstCarousel .flickity-page-dots .dot {
  background: none !important;
  color: white !important;
  font-family: "55roman", sans-serif !important;
  opacity: 1 !important;
  margin-bottom: 1rem;
}
.firstCarousel .dot.is-selected {
  color: black !important;
}
.firstCarousel .flickity-page-dots .dot::after {
  content: counter(a);
  counter-increment: a;
}

/* END OF TOP AND LEFT NAV */

.myCarousel .carousel-cell {
  width: 100%;
}
.carousel-nav {
  margin-top: 0.7rem;
  width: 100%;
  position: absolute;
  z-index: 4;
  /* position: relative;
  right:140px; */
}
.carousel-nav .carousel-cell {
  display: flex;
  align-items: center;
  position: fixed;
  height: 40px;
  color: #9a9a9a;
  font-size: 18px;
  font-family: "65medium", sans-serif;
}

.carousel-nav .carousel-cell:after {
  content: "\002B";
  font-size: 22px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-weight: 700;
  transition: all 0.8s ease;
}

.carousel-nav .carousel-cell.is-selected {
  color: black;
  transition: all 1s ease;
}
.carousel-nav .carousel-cell.is-selected:after {
  content: "\2014";
  font-size: 22px;
  color: #874834;
  padding-bottom: 5px;
  padding-left: 15px;
  font-weight: 700;
}

@media screen and (max-width: 940px) {
  .main-carousel-container {
    width: 100%;
  }
  .firstCarouselContainer {
    width: 100%;
  }

  .background-image-block {
    left: 0;
    width: 100%;
  }
  .myBtn {
    background-color: transparent;
    border: 1px solid black;
    padding: 8px;
    border-radius: 0;
    float: right;
    margin-top: 2rem;
    margin-bottom: 5rem;
    margin-right: 2.5rem;
  }
  .myBtn a {
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    color: black;
  }
  .myBtn a .btn_icon {
    font-size: 20px;
    font-weight: 500;
  }
  .description-block-container {
    /* display: none; */
    /* position: absolute;
    bottom: 50vh;
    right:0vh; */
    /* background-color: #874834; */
  }

  .description-block {
    padding: 0 2.5rem;
  }
  .description-block h1 {
    font-size: 5.5vh;
    font-family: "65medium", sans-serif;
    text-transform: uppercase;
    line-height: 3.5rem;
  }
  p.description {
    font-size: 1.8vh;
    width: 100%;
    line-height: 2.3vh;
    max-height: (2.3vh * 3);
  }
  .firstCarousel .flickity-page-dots {
    counter-reset: a;
    position: absolute;
    width: auto;
    height: max-content;
    top: 5%;
    left: 25%;
    right: 0%;
    display: flex;

    flex-direction: row;
  }
  .carousel-nav .carousel-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding: 0 15rem 0 0; */
    /* margin-right: 2rem; */
    width: 30% !important;
    height: 30px;
    color: #9a9a9a;
    font-size: 12px;
    font-family: "65medium", sans-serif;
    margin-right: 5px;
  }
  .carousel-nav {
    padding: 0 2px;
    overflow-x: scroll;
    right: 0;
    width: 100vw;
  }
  .carousel-nav .carousel-cell:after {
    content: "\002B";
    font-size: 15px;
    padding-bottom: 5px;
    padding-left: 5px;
    font-weight: 700;
    transition: all 0.3s ease;
  }

  .carousel-nav .carousel-cell.is-selected {
    color: black;
  }
  .carousel-nav .carousel-cell.is-selected:after {
    content: "\2014";
    font-size: 15px;
    color: #874834;
    padding-bottom: 5px;
    padding-left: 5px;
    font-weight: 700;
    transition: all 0.3s ease;
  }
}
