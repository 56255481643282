@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.body {
  position: relative;
  min-height: 100%;
  padding-bottom: 10rem;
  background-image: url("../../images/light_background.png");
  background-size: contain;
  padding-top: 8rem;
}
.main {
  min-height: 100vh;
}

.sectionOne {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container {
  width: 75%;
  height: auto;
}
.container-2 {
  margin: auto;
  width: 82%;
}
.sectionOne .banner-container {
  margin-left: auto;
  margin-right: 0;
  width: calc(100% - 5.3%);
  height: 80vh;
}
.sectionOne .front-cover {
  height: 100%;
  background-size: cover;
  background-position-y: 80%;
}

.main-header {
  width: 100%;
  margin-top: 70px;
  max-width: 40vw;
}
.main-header h1 {
  font-size: 65px;
  font-weight: medium;
  margin-bottom: 30px;
  color: black;
  font-family: "65medium", sans-serif;
}
.sectionOne h3 {
  font-size: 35px;
  font-family: "65medium", sans-serif;
}
/* .writings {
  margin: 50px 0px;
} */
.section.first {
  display: flex;
  justify-content: center;
  font-family: "55roman", sans-serif;
  margin-top: 7rem;
}
.section h3 {
  color: #8c4a37;
  font-size: 45px;
  margin-bottom: 2rem;
  font-family: "65medium", sans-serif;
  font-weight: 400;
}
.section.first p {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: justify;
  font-family: "65medium", sans-serif;
  font-weight: 400;
  color: black;
  opacity: 0.7;
}
.section.second {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.items {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 3rem;

  margin: 2rem 0;
}

.items:nth-child(2) {
  margin-top: 5rem;
}
.items .image {
  width: 60%;
}
.items .image img {
  width: 100%;

  height: auto;
}
.items .writings {
  width: 35%;

  box-sizing: border-box;
}
.items h3 {
  font-size: 35px;
  color: #8c4a37;
  margin-bottom: 30px;
  font-family: "65medium", sans-serif;
  font-weight: 400;
}

.items p {
  font-size: 24px;
  font-family: "65medium", sans-serif;
  font-weight: 400;
  color: black;
  opacity: 0.7;

  margin-bottom: 20px;
  text-align: justify;
}
/* .revert {
  display: flex;
} */
.opportunities {
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
  margin-top: 4rem;
}
.opportunities .jobs {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  border-top: 1px solid #9a999a;
  padding-top: 30px;
  box-sizing: border-box;
}

.opportunities h1 {
  font-size: 60px;
  font-weight: normal;
  color: #8c4a37;
  font-family: "65medium", sans-serif;
}
.opportunities .noVacancy p {
  color: #844939;
  font-size: 32px;
  margin-top: 2rem;
  font-family: "Helvetica 55 Roman", sans-serif;
  font-style: italic;
}
.opportunities .vacancy h3,
.opportunities .vacancy a {
  font-size: 35px;
  font-weight: normal;
  text-transform: capitalize;
  color: #8c4a37;
  margin: 20px 0px 2rem 0;
  font-family: "45light", sans-serif;
  font-style: italic;
  font-weight: lighter;
  text-decoration: none;
}
.opportunities .descFlexbox p {
  font-size: 20px;
  font-weight: normal;

  font-family: "55roman", sans-serif;
}
.opportunities .vacancy {
  width: 100%;
  padding-right: 0px;
  margin-top: 5rem;
  border-bottom: 1px solid #9a999a;
}
.vacancy .descFlexbox {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.vacancy .descFlexbox .jobInfo {
  width: 100%;
}
.opportunities .right {
  width: 50%;
  text-align: right;
  font-family: "Helvetica 65 Medium", sans-serif;
}
.opportunities .right h6 {
  font-size: 14px;
  font-family: "Helvetica 65 Medium", sans-serif;
  text-transform: capitalize;
}
.loading {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* FOOTER */
.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
@media screen and (max-width: 430px) {
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .sectionOne .banner-container {
    margin: auto;
    width: 100vw;
    height: 25vh;
  }
  .container {
    width: 70%;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .image-size {
    height: 25vh;
  }
  .sectionOne h3 {
    font-size: 20px;
    text-align: center;
  }
  .section h3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 25px;
  }
  .section.first p {
    margin-bottom: 20px;
    font-size: 15px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
  }
  .items .image,
  .items .writings {
    width: 100%;
  }
  .items .writings p {
    font-size: 15px;
  }

  section.second h2 {
    font-size: 25px;
    font-weight: 400;
  }
  section.opportunities h1 {
    font-size: 30px;
  }
  section.opportunities p {
    font-size: 12px;
  }
  section.opportunities .left h3 a {
    font-size: 25px;
    margin: 5px 0;
  }
  section.opportunities .left {
    width: 100%;
  }
  section.opportunities .right {
    width: 100%;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }
}
