@media (hover: hover) and (pointer: fine) {
  #cursor {
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    will-change: transform;
  }

  #cursor .cursor--inner {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #8c4a37;
  }
}
