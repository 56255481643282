@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "65medium", sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  padding-bottom: 15rem;
  min-height: 100%;
  background-image: url("../../images/grey_background.png");
  background-size: contain;
  /* background-position: cover;
  background-repeat: no-repeat; */
}
.main {
  /* margin-bottom: 12rem; */
  min-height: 100vh;

  /* margin-bottom: 250px; */
}
.container {
  width: 70vw;
  height: auto;
  margin: auto;
}
.container-2 {
  padding-left: 70px;
  box-sizing: border-box;
  width: 100%;
}
.mini-navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8rem;
}
.mini-navbar li {
  text-decoration: none;
  color: black;
}
.mini-navbar a:hover {
  color: black;
}
.mini-navbar ul {
  display: flex;
  justify-content: end;
  gap: 25px;
  font-size: 12px;
}
.mini-navbar ul li {
  display: inline;
  /* margin-right: 50px; */
  text-transform: uppercase;
  padding: 0 0px 3px 0px;
  font-family: "Helvetica 65 Medium", sans-serif;
  text-align: right;
}

.main-header {
  width: 100%;
  margin-top: 6rem;
  margin-bottom: 5rem;
}
.main-header h1 {
  font-size: 75px;
  font-weight: medium;
  margin-bottom: 40px;
  color: black;
  font-family: "Helvetica 65 Medium", sans-serif;
}
.active {
  color: black;
  border-bottom: 3px solid #844939;
  padding-bottom: 3px;
  transition: all ease 0.3s;
}
.items {
  font-family: "55roman", sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
  column-gap: 2rem;
}
.items a {
  color: black;
}
.item {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  align-items: center;
  scale: 1;
  transition: all ease-in-out 0.5s !important;
}
.item:hover {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  align-items: center;
  scale: 1.08;
  transition: all ease-in-out 0.5 !important;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
}
.image {
  height: 30vh;
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.item p {
  font-size: 16px;
  padding: 15px 15px;
  color: black;
  font-family: "Helvetica 65 Medium", sans-serif;
  margin-right: auto;
}

.noNews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #acaca9;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: white;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* END OF FOOTER */
@media screen and (max-width: 430px) {
  .mini-navbar {
    padding-top: 6rem;
  }
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .container {
    width: 75%;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .mini-navbar ul {
    display: flex;
    justify-content: flex-end;
    font-size: 8px;
    font-weight: lighter;
  }
  .mini-navbar ul li {
    display: inline;
    margin-right: 0px;
  }
  .items {
    display: flex;
    flex-direction: column;
  }
  .item {
    width: 100%;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }
}
