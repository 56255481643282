.owl-nav {
  position: absolute;
  top: -3rem;
  right: 15px;
}
.owl-dots {
  display: none;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  transform: all 0.5s ease;
  color: black !important;
  font-family: "65medium", sans-serif !important;
  font-size: 12px !important;
  background-color: transparent;
}
.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background: none !important;
  color: black !important;
  opacity: 0.5;
}

@media screen and (max-width: 900px) {
  .owl-nav {
    right: 8px;
  }
}
