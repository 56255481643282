@font-face {
  font-family: "67condensed";
  src: url(../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}

/* hamburger */
.hamburger {
  display: none;
  justify-content: center;
  align-items: center;
}
.hamburger li {
  list-style: none;
}
.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.hamRotate.active {
  transform: rotate(45deg);
}
.hamRotate180.active {
  transform: rotate(180deg);
}
.line {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  /* stroke:white; */
  stroke-width: 5.5;
  stroke-linecap: round;
}
#linecolor {
  stroke: black;
}
.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}

/* end of hamburger */

/* MENU DISPLAY */
.navbar .wrapper {
  display: flex;
  justify-content: start;
}
.menu_display {
  background-color: #874834;

  height: 100vh;
  width: 0%;
  opacity: 0;
  justify-content: start;
  align-items: center;
  position: fixed;
  z-index: 5;
  transition: all 0.6s ease;
}
.menuOpen {
  width: 65%;
  opacity: 1;
}
.darkOverlay {
  position: absolute;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  width: 0;
  right: 0;
  color: transparent;
  z-index: 3;
  transition: all 0.6s ease;
}
.darkOverlayActive {
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  width: calc(100% - 65%);
  right: 0;
  color: transparent;
  z-index: 3;
  transition: all 0.6s ease;
}
/* .menuOpen::after {
  content: "";
  position: absolute;

  right: 0;
  height: 100vh;
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0.2);
} */

.menuOpenIcon {
  font-size: 26px;
  font-weight: 100;
  color: white;
  transition: all 0.6s ease;
}

.menu_display .container {
  height: 94.5%;
  position: inherit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 8.3rem;
}
.noDisplay {
  display: none;
}
.menu_display .container li {
  list-style: none;
  font-size: 7vh;
  /* margin-bottom: 10px; */
  font-weight: 400;
}
.menu_display .container .navLink {
  color: white;
  opacity: 0.5;
  text-decoration: none;
}

.menu_display .container li :hover,
.currentPage {
  color: white;
  opacity: 1;
}
/* END OF MENU DISPLAY */

/* TOP AND LEFT NAV */
.darkHeader {
  text-decoration: none;
  color: white;
  font-family: "67condensed", sans-serif;
  font-size: 15px;
}

.lightHeader {
  text-decoration: none;
  color: black;
  font-family: "67condensed", sans-serif;
  font-size: 15px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 3rem 5rem 3rem 8rem;
  margin-bottom: 3rem;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100vw;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 5.3%;
  position: fixed;
  z-index: 999998899;
}
.search-social{
  height: auto;
}
.empty{
  height: 33%;
}
ul.empty{
  height: 0;
}
.menu {
  display: flex;
  align-items: center;
  height: 33.3%;

}

.search-social {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 33.3%;
  margin-bottom: .5rem;
}
.menuIcon {
  font-size: 26px;
  font-weight: 100;
  color: #9a9a9a;
  transition: all 0.6s ease;
}
.search-social .searchIcon,
.search-social .linkedinIcon {
  font-size: 26px;
  font-weight: 100;
  color: #9a9a9a;
  transition: all 0.6s ease;
}
.menuIconWhite,
.searchIconWhite,
.linkedinIconWhite {
  font-size: 26px;
  font-weight: 100;
  color: white;
  transition: all 0.6s ease;
}

.menuIconBlack,
.searchIconBlack,
.linkedinIconBlack {
  font-size: 26px;
  font-weight: 100;
  color: black;
  transition: all 0.6s ease;
}
.linkedinIcon,
.linkedinIconBlack,
.linkedinIconWhite, .searchIcon, .searchIconBlack, .searchIconWhite {
  margin-top: 15px;
  margin-bottom: 5px;
  /* margin-bottom: 20px; */
}

.header .logo {
  width: 12rem;
}
.dropdown {
  width: 50%;
  /* height: 15vh; */
  height: 35px;
  padding: 2px 0;
}

.dropdown .span {
  text-decoration: none;
  font-size: 15px;
  display: block;
  margin-top: 10px;
  /* transform: translateX(-40px); */
  animation: slideIn 0.3s;
  /* border-bottom: 1px solid rgb(255, 255, 255, 0.4); */
}
@keyframes slideIn {
  0% {
    transform: translateX(-40px);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
.aboutLink {
  display: flex;
  align-items: center;
}
.abtIcon {
  /* font-size: 78px; */
  font-size: 30px;

  transition: all ease 0.3s;

  padding: 0;
  /* transform: translateX(-18px); */
  box-sizing: content-box;
  /* transform: rotate(-90deg); */
}
.abtIconOpen {
  /* font-size: 78px; */
  font-size: 30px;
  transform: rotate(-90deg);
  transition: all ease 0.3s;
}

.header .navMobile {
  display: none;
}

/* END OF TOP AND LEFT NAV */

@media screen and (max-width: 500px) {
  /* body {
    background-size: cover;
    background-repeat: repeat;
    background-position: center;
    background-image: url("../images/light_background.png");
    height: 100%;

  } */

  /* MENU DISPLAY */
  .menu_display {
    background-color: #874834;
    height: 100vh;
    /* width: 100vw; */
    justify-content: start;
    align-items: center;
    position: fixed;
    z-index: 3;
  }
  .menuOpen {
    width: 100%;
    opacity: 1;
  }
  /* .menu_display .empty {
    display: none;
  } */
  /* .menu_display .container {
    height: 100%;
  } */
  .menuIconWhite,
  .searchIconWhite,
  .menuIconBlack,
  .searchIconBlack {
    font-size: 23px;
  }

  .menu_display .container {
    /* height: 90%; */
    display: flex;
    padding-top: 5vh;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    padding-left: 30px; /*MOBILE MENU PADDING */
  }
  .menu_display ul:last-child {
    margin-top: 5vh;
  }
  .menu_display .container li {
    list-style: none;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .menu_display .container a {
    color: white;
    opacity: 0.5;
  }
  .menu_display .container li :hover {
    color: white;
    opacity: 1;
  }
  ul.empty{
  }
  .dropdown {
    width: 100%;
  }
  .dropdown .span {
    font-size: 13px;
  }
  /* END OF MENU DISPLAY */

  /* HAMBURGER */

  .hamburger {
    display: flex;
  }
  .header .logo {
    width: 10rem;
  }
  /* END OF HAMBURGER */

  /* LEFT AND TOP NAV */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 3;
  }
  .darkOverlay,
  .darkOverlayActive {
    display: none;
  }
  .nav {
    display: none;
  }
  .header .navMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3vw;
  }
  .header .logo {
    width: 10rem;
    /* padding-top: 1vh; */
  }
}
