@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  background-image: url("../../images/grey_background.png");
  background-size: contain;
  padding-bottom: 1rem;
  min-height: 100%;
  padding-top: 10rem;
  padding-bottom: 7rem;
}
.mainContainer {
  width: 72%;
  height: auto;
}

.mini-navbar {
  display: flex;
  justify-content: space-between;
}

.mini-navbar .container {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
}
.mini-navbar a {
  text-decoration: none;
  color: black;
}
.mini-navbar h3 {
  font-size: 12px;
  font-weight: 700;

  font-family: "Helvetica 65 Medium", sans-serif;
}

.main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 100vh;
  margin-bottom: 3rem;
  /* margin-bottom: 250px; */
}
.main-header {
  width: 100%;
  margin-top: 20px;
}
.main-header h1 {
  font-size: 65px;
  font-weight: medium;
  margin-bottom: 30px;
  color: black;
  font-family: "Helvetica 65 Medium", sans-serif;
}
.container img {
  width: 100%;
}
.main .newsBlock p {
  margin-bottom: 25px;
  font-size: 20px;
  font-family: "Helvetica 45 Light", sans-serif;
}

.newsBlock {
  margin: 3rem 0;
}
.newsBlock ul{
  padding-left: 1.2vw;
}
.newsBlock .imageCaption {
  font-size: 13px;
  padding: 10px 0;
  text-align: center;
}
.paragraph {
  margin-top: 50px;
}
.loading {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ACACA9;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: white;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* END OF FOOTER */
@media screen and (max-width: 430px) {
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .main p {
    font-size: 14px;
    text-align: justify;
  }
  .container {
    width: 95%;
  }
  .mini-navbar .container {
    width: 100%;
    padding: 0 15px;
  }
  .mini-navbar .container p {
    margin-right: 0;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .newsBlock ul{
    padding-left: 4.2vw;
  }
  
  .mini-navbar ul {
    display: flex;
    justify-content: space-around;
    font-size: 8px;
    font-weight: lighter;
  }
  .mini-navbar ul li {
    display: inline;
    margin-right: 0px;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

}
