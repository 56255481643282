/* .business-portfolio .item {
  width: 95%;
  height: 60vh;
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  background-image: url("../../../images/PrimeTech_01.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.business-portfolio .item::before {
  content: "";
  background-image: url("../../../images/PrimeTech_01.jpg") no-repeat center
    center fixed;
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
}
.business-portfolio p {
  position: relative;
  font-size: 20px;
  font-family: "55roman", sans-serif;
  color: white;
  margin: 10px 20px;
}

.business-portfolio a {
  position: relative;
  border: 1px solid white;
  padding: 5px 10px;
  
  width: 30%;
  border-radius: 35px;
  color: white;
  font-size: 12px;
  font-family: "65medium", sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.business-portfolio object,
section#references object {
  padding-left: 5px;
} */

@font-face {
  font-family: "67condensed";
  src: url(../../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../../Fonts/45-light.otf);
}
.item {
  height: 80vh;
  padding-bottom: 10px;
}
.owlImage {
  /* background-image: url("../../../images/PrimeTech_01.jpg"); */
  background-size: cover;
  background-position: center;
  height: 85%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 0 0 35px 35px;
  font-size: 45px;
  font-family: "55roman", sans-serif;
  color: white;
  margin-bottom: 2.5rem;
}
.owlBtn {
  display: flex;
  margin-left: 35px;
  align-items: center;
  border: 1px solid white;
  padding: 5px 10px;
  color: white;
  background-color: black;
  border-radius: 20px;
}
.owlBtn a {
  text-decoration: none;
  color: white;
  font-size: 12px;
  font-family: "65medium", sans-serif;
}

/*         ----------------------    Unaplied     ---------------------------- */
.owl-container {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 100px;
}
.owl-nav {
  position: absolute;
  top: -3rem;
  right: 15px;
}
.owl-dots {
  display: none;
}
.owl-nav .owl-prev,
.owl-nav .owl-next {
  transform: all 0.5s ease;
  color: white !important;
  font-family: "65medium", sans-serif !important;
  font-size: 12px !important;
}
.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background: none !important;
  color: white !important;
  opacity: 0.5;
}
