/* PROFILE CAROUSEL */

/* .slick-slide div {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  height: 70vh;
  padding-bottom: 50px;
} */

.slick-slide img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: center;
}
@media screen and (max-width: 430px) {
  .slick-slide img{
    height: auto;
  }
}