@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "65medium", sans-serif;
  text-decoration: none;
}

.searchPage {
  background-color: #874834;
  position: static; /*change to fixed to display search in fullscreen  */
  height: 100vh;
  width: 100vw;
  z-index: 5;
  align-items: center;
}
.searchBox {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchBox input {
  border: none;
  outline: none;
  text-align: center;
  font-size: 26px;
  color: white;
  width: 60vw;
  background-color: #874834;
}
.searchBox input::placeholder {
  text-align: center;
  font-size: 26px;
  color: white;
  opacity: 0.5;
}
.searchBox .inputIcon {
  color: rgba(255, 255, 255, 0.3);
}
.searchBox .inputIcon:hover {
  color: rgba(255, 255, 255, 1);
}

.search-social {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.search-social img {
  padding-bottom: 20px;
  height: 40px;
}
.header .logo {
  width: 12rem;
}

/* Result component */

.body {
  position: relative;
  padding-bottom: 15rem;
  min-height: 100%;
  background-image: url("../../images/light_background.png");
  background-size: contain;
  /* background-position: cover;
  background-repeat: no-repeat; */
}
.main {
  /* margin-bottom: 12rem; */
  min-height: 100vh;
  padding-top: 8rem;
  /* margin-bottom: 250px; */
}
.container {
  width: 70vw;
  height: auto;
  margin: auto;
}
.resultItem {
  margin-bottom: 2rem;
}
.resultItem > .title {
  color: #874834;
  font-size: 32px;
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resultItem > .description {
  display: block;
  font-weight: 200;
  width: 100%;
  margin-bottom: 0.5rem;
  line-height: 1.2rem;
  max-height: calc(1.2rem * 2);
  overflow: hidden;
  white-space: pre-line;
  text-overflow: ellipsis;
}
.resultItem > .subtext {
  font-size: 14px;
  margin-bottom: 0.2rem;
}
.noResultContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.noResult {
  color: #874834;
  font-size: 38px;
}
.paginationContainer {
  list-style-type: none;
  display: flex;
}
.page {
  color: rgb(99, 96, 96);
  cursor: pointer;
  margin-right: 1rem;
}
.activePage {
  color: black;
}
.nextButton {
  margin-left: 1.5rem;
  cursor: pointer;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* END OF FOOTER */
@media screen and (max-width: 430px) {
  .body {
    padding-bottom: 0;
  }
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .container {
    width: 75%;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .searchBox input {
    width: auto;
  }
  .inputIcon {
    display: none;
  }
  .resultItem > .title {
    font-size: 17px;
  }
  .resultItem > .description {
    font-size: 15px;
  }
  .resultItem > .subtext {
    font-size: 13px;
  }
  .noResult {
    font-size: 20px;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

}
