* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p.description {
  position: absolute;
  top: 50%;
  /* color: 'red'!important */
}
.body {
  position: relative;
  min-height: 100%;
  background-image: url("../../images/light_background.png");
  /* background-color: grey;
  background-blend-mode: darken; */
  background-size: contain;
  padding-top: 7rem;
  transition: all ease-in-out 0.8s;
}
.bodyDark {
  position: relative;
  min-height: 100%;
  background-image: url("../../images/dark_background.png");
  /* background-color: grey;
  background-blend-mode: darken; */
  background-size: contain;
  padding-top: 8rem;
  transition: all ease-in-out 0.8s;
}
.bodyGrey {
  position: relative;
  min-height: 100%;
  background-image: url("../../images/grey_background.png");
  background-size: contain;
  padding-top: 8rem;
  transition: all ease-in-out 0.8s;
}
.main {
  min-height: 100vh;
}
.imageContainer {
  width: 100%;
  padding-left: 5.3%;
  min-height: 90vh;
  margin-left: auto;
  margin-right: 0;
  /* margin-top: 5rem; */
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 1s;
  /* background-color: rgb(154, 153, 154); */
}

.video {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 70vw;
  margin: auto;
}
/*                                 SEGMENTS                                     */

.segmentsContainer {
  width: 70vw;
  display: flex;
  justify-content: end;
  overflow: hidden;
  /* background-color: rgb(128, 128, 128, 0.5); */
}

.segments-items {
  margin-bottom: 5rem;
}

.segments {
  /* background-color: rgb(0, 0, 0, 0.3); */
  min-height: 100vh;
  /* padding-bottom: 5rem; */
}
#segmentsHeading {
  position: sticky;
  top: 10rem;
}
section#news {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
.segments h2 {
  font-family: "65medium", sans-serif;
  font-size: 8vh;
  color: #874834;
  margin: 6rem 0px 50px 0px;
}
.segments-items {
  display: flex;
  justify-content: flex-end;

  /* align-items: flex-start; */
}

.segments-items a,
section#references .button a {
  color: black;
  font-size: 12px;
  font-family: "65medium", sans-serif;
  margin: 0;
  padding: 10px 25px;
  border: 1px solid black;
  border-radius: 5px;
}
.segments-items .items,
section#news .items {
  width: 85%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 50px;
  padding-right: 0;
}
.segments-items .items .item {
  width: 90%;
  margin-top: 4rem;
  margin-bottom: 15px;
  font-family: "55roman", sans-serif;
}
section#news .items .item {
  width: 45%;
  margin-bottom: 15px;

  font-family: "55roman", sans-serif;
}
.segments-items .segmentNumber {
  font-size: 14px;
  color: white;
  font-family: "55roman", sans-serif;
}
.segments-items .segmentTitle {
  font-size: 45px;
  line-height: 42px;
  color: black;
  font-weight: 700;
  font-family: "Helvetica 55 Roman", sans-serif;
}
.segments-items .item .textContainer {
  width: 100%;
  margin-bottom: 4rem;
}
/* .textContainer {
  font-family: "55roman", sans-serif !important;
} */
.segments-items #segmentText {
  font-size: 20px;
  opacity: 0.5;
  hyphens: auto;

  margin: 20px 0px;
  text-align: justify;
  font-family: "55roman", sans-serif !important;
  font-weight: lighter;
  max-height: calc(1.8rem * 6);
  line-height: 1.8rem;
  /* overflow: hidden; */
  white-space: pre-line;
  text-overflow: ellipsis;
}

/*                    BUSINESS PORTFOLIO          */

.learnMoreBlack {
  display: flex;
  color: white;
  margin-left: 35px;
  align-items: center;
  border: 1px solid white;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 20px;
}

.learnMoreWhite {
  display: flex;
  color: black;
  margin-left: 35px;
  align-items: center;
  border: 1px solid black;
  padding: 5px 10px;
  background-color: transparent;
  border-radius: 20px;
}
.portfolioSpan {
  height: 13.3px;
}

.business-portfolio {
  display: flex;
  justify-content: center;
  /* background-image: url("../../images/dark_background.png");
  background-position: center;
  background-size: 100%; */
  height: auto;
  /* padding: 10rem 0; */
}
.container-2 {
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.business-portfolio h2 {
  margin: 50px auto 5rem auto;

  width: 80%;
  font-family: "65medium", sans-serif;
  font-size: 7.7vh;
  color: black;
}

/* --------------------------- REFERENCES ------------------- */
.references {
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: "65medium", sans-serif;
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column; */
  padding: 4rem 0 0 0;
}
.references .container-2 > h2 {
  font-family: "65medium", sans-serif;
  font-size: 65px;
  margin: 3rem 0 1rem 0;
}
.references .container-2 p {
  margin-bottom: 2rem;
  opacity: 0.5;
}

/*                                 NEWS                         */

.newsFlexbox {
  display: flex;
  /* column-gap: 50px; */
  flex-wrap: wrap;
  justify-content: space-between;
}
.news a {
  /* border: 1px solid white; */
  width: auto;
  padding: 10px 25px;
  border-radius: 10px;
  /* color: white; */
  font-size: 12px;
  font-family: "65medium", sans-serif;
}
.readMoreWhite {
  border: 1px solid white;
  color: white;
  transition: all ease 0.8s;
}
.readMoreBlack {
  border: 1px solid black;
  color: black;
  transition: all ease 0.8s;
}
.moreProjectsWhite {
  transform: translateY(-40px);
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid black;
  padding: 10px 25px;
  color: white;
  transition: all ease 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.moreProjectsBlack {
  transform: translateY(-40px);
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid White;
  padding: 10px 25px;
  color: black;
  transition: all ease 0.8s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news .container-2 h2 {
  font-family: "65medium", sans-serif;
  margin: 50px auto 5rem auto;

  width: 80%;
  font-family: "65medium", sans-serif;
  font-size: 7.7vh;
}
.news .items {
  width: 100%;
  /* display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 50px; */
  flex-wrap: wrap;
}
.readMoreFlex {
  display: flex;
  justify-content: flex-end;
}
.news .items .item {
  width: 48%;
  margin: 20px 0px;
  font-family: "55roman", sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
  /* border-bottom: 2px solid white; */
  padding-top: 50px;
  gap: 30px;
  flex-wrap: wrap;
}
.borderWhite {
  border-bottom: 2px solid white;
}
.borderBlack {
  border-bottom: 2px solid black;
}
.news .items .item h5 {
  font-size: 20px;
  font-family: "55roman", sans-serif;
  width: 77%;
}
.news .items .item h6 {
  width: 23%;
  text-align: right;
  font-size: 12px;
}
.textWhite {
  color: white;
}
.textBlack {
  color: black;
  padding-top: 4.5px;
}

.news .items .item a {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}

/* JOIN OUR TEAM */
.join {
  display: flex;
  justify-content: center;
  height: 100vh;
  padding-top: 50px;
}
.join .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  position: relative;
}
.join .insignia {
  width: 45%;
}
.join h2 {
  font-size: 9vh;
  color: black;
  font-family: "65medium", sans-serif;
  /* position: relative;
  right: 80px; */
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}
.join a {
  border: 1px solid black;
  width: auto;
  padding: 10px 25px;
  border-radius: 10px;
  color: black;
  font-size: 12px;
  font-family: "65medium", sans-serif;
}
.joinText {
  position: absolute;
  right: 8.5rem;
}

/*                            FOOTER                     */
.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid #9a999a;

  /* background-image: url("../../images/dark_background.png"); */
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: black;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/*                MEDIA QUERIES              */
@media screen and (max-width: 500px) {
  .body {
    padding-top: 5rem;
    /* overflow-x: hidden; */
  }
  .main {
    /* margin-top: 10rem; */
  }

  .firstSlideshow {
    transform: translateY(0rem);
  }
  .firstSection {
    width: 100vw;
  }
  .segments .container {
    width: 100vw;
    margin: auto;
  }
  /*                                 SEGMENTS                                     */

  .segments-items {
    margin-bottom: 5rem;
  }
  .segments-items .item .textContainer {
    display: block;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    line-height: 1.7rem;
    max-height: calc(7 * 1.65rem);
  }

  .segments {
    /* background-color: rgb(0, 0, 0, 0.3); */
    min-height: 100vh;
    padding-bottom: 5rem;
    height: auto;
  }
  #segmentsHeading {
    position: unset;
  }
  .segments #segmentsHeading h2 {
    font-size: 6vh;
    padding-left: 15px;
  }
  .segments-items {
    justify-content: center;
  }
  .segmentsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: auto;
    /* overflow: hidden; */
  }

  .segments-items .items .item,
  section#news .items .item {
    width: 90%;
    margin-bottom: 15px;
  }
  .segment-items .segmentTitle {
    font-size: 30px;
  }
  .segment-items #segmentText {
    font-size: 14px;
  }
  .business-portfolio {
    display: flex;
    justify-content: center;
    /* background-image: url("../../images/dark_background.png");
    background-position: center;
    background-size: 100%; */
    height: auto;
    padding: 2rem 0;
  }
  .container-2 {
    width: 100%;
    margin: auto;
  }
  .container {
    width: 100%;
    margin: auto;
  }
  .business-portfolio h2,
  .news .container-2 h2 {
    margin: 30px auto 3rem auto;

    width: 100%;
    font-family: "65medium", sans-serif;
    font-size: 6vh;
    padding-left: 15px;
  }
  .references .container-2 > h2 {
    font-family: "65medium", sans-serif;
    font-size: 45px;
    margin: 3rem 0 1rem 0;
  }
  .references .container-2 p {
    font-size: 14px;
  }
  .moreProjectsWhite,
  .moreProjectsBlack {
    padding: 5px 10px;
  }
  .news {
    padding: 0 5%;
  }
  .news .items .item {
    width: 100%;
    margin: 20px 0px;
    font-family: "55roman", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    /* border-bottom: 2px solid white; */
    padding-top: 50px;
    gap: 0px;
    flex-wrap: wrap;
  }
  .news .items {
    width: 100%;
    /* display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 50px; */
    flex-wrap: wrap;
  }
  .readMoreFlex {
    padding-top: 3rem;
  }

  /* JOIN OUR TEAM */
  .join {
    display: flex;
    justify-content: center;
    /* background-image: url("../../images/dark_background.png");
  background-position: center;
  background-size: 100%; */
    height: 70vh;
    padding: 20px 6.5%;
  }
  .join .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    position: relative;
  }
  .join h2 {
    font-size: 3.2vh;
    padding-top: 3rem;
    color: black;
    font-family: "65medium", sans-serif;
    /* position: relative;
  right: 80px; */
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .join a {
    border: 1px solid black;

    padding: 4px 15px;
    border-radius: 10px;
    color: black;
    font-size: 12px;
    font-family: "65medium", sans-serif;
  }
  .joinText {
    position: absolute;
    right: 0;
  }
  .join .insignia {
    width: 43%;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }

  .imageContainer {
    width: 100%;
    padding-left: 0;
    min-height: 60vh;
    margin-left: auto;
    margin-right: 0;
    margin-top: 0;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease 1s;
    /* background-color: rgb(154, 153, 154); */
  }
}
