@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}

/* CONTAINERS */
.container {
  width: 70%;
  height: auto;
  margin: auto;
}
.container-2 {
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
  height: auto;
}
.body {
  position: relative;
  min-height: 100%;
  padding-bottom: 10rem;
  background-image: url("../../images/light_background.png");
  background-size: contain;
}
.main {
  min-height: 100vh;
}
.portfolio {
  padding-top: 7rem;
}
/* END OF CONTAINERS */

/*MAIN*/
.sectionOne,
.civil-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sectionOne .front-cover {
  display: flex;
  justify-content: flex-end;
}
.front-cover {
  width: 100%;
  margin-bottom: 50px;
}
.image-size {
  width: 100%;
  height: 85vh;
  object-fit: cover;
  object-position: center;
}
.main-header {
  width: 100%;
  margin-top: 15rem;
}
.main-header h1 {
  font-size: 75px;
  font-weight: medium;
  margin-bottom: 4rem;
  color: black;
  font-family: "Helvetica 65 Medium", sans-serif;
}

.sectionOne h3 {
  font-size: 35px;
  font-family: "Helvetica 65 Medium", sans-serif;
}

/* END OF MAIN */

/* SECTION */
#section-nav ul {
  border-bottom: none;
  display: flex;
  justify-content: space-between;

  margin-top: 4rem;
}
#section-nav ul li {
  display: inline;
  margin-right: 2vw;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Helvetica 65 Medium", sans-serif;
}
#section-nav {
  border-bottom: 1px solid #9a999a;
  padding-bottom: 3rem;
}
#section-nav a {
  text-decoration: none;
  color: inherit;
}
#section-nav li {
  color: #9a999a;
  text-decoration: none;
  font-family: "Helvetica 65 Medium", sans-serif;

  font-size: 14px;
}
#section-nav a:hover {
  color: black;
}
/* SECOND SECTION (BUILDINGS) */
.section.second .category {
  margin-top: 7%;
  height: 20px;
}
.section.second .category p {
  font-size: 15px;
}
.section.second .block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;
}
.block .item-one {
  width: 40%;
}
.block .item-one h2 {
  font-size: 60px;
  color: #8c4a37;
  font-weight: 700;
  font-family: "Helvetica 55 Roman", sans-serif;
}
.block .item-two,
.block .capacities {
  width: 47%;
  height: auto;
  font-size: 24px;
  font-family: "45light", sans-serif;
  font-weight: lighter;
  margin-bottom: 20px;
  text-align: justify;
}

.block .item-two p,
.item-two ul li {
  font-family: "65medium", sans-serif;
  font-size: 24px;
  font-weight: 400;
  color: black;
  opacity: 0.7;
  margin-bottom: 10px;
}
.block .item-two h1,
.block .item-two h2,
.block .item-two h3 {
  font-size: 24px;
}
.item-two ul {
  margin-top: 15px;
  padding-left: 5%;
}

/* INFRASTRUCTURE */

.section.view {
  margin-top: 10rem;
  margin-bottom: 200px;
}
.section.view .container {
  display: flex;
  gap: 7rem;
}
.section.view a {
  color: black;
}
.section.view h3 {
  font-size: 50px;
  font-family: "Helvetica 65 Medium", sans-serif;
  margin-right: 40px;
}

.loading {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* END OF SECTION */

/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  /* background-image: url("../../images/dark_background.png"); */
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
/* END OF FOOTER */

/* RESPONSIVE */

@media screen and (max-width: 430px) {
  /* CONTAINERS */

  /* .container {
    width: 100%;
  }

  .block-container {
    width: 70%;
  } */

  .sectionOne {
    transform: translateY(35px);
    /* margin-bottom: 1.5rem; */
  }
  .container-2 {
    padding-left: 0;
    width: 100vw;
  }
  .image-size {
    height: 25vh;
  }
  /* END OF CONTAINERS */

  /* MAIN */
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .sectionOne h3 {
    font-size: 20px;
    text-align: left;
  }

  /* END OF MAIN */

  /* SECTION */
  #section-nav {
    display: none;
  }
  .block {
    flex-direction: column;
  }
  .portfolio {
    padding-top: 4rem;
  }
  .section .item-one h2 {
    font-size: 35px;
    color: #8c4a37;
    font-weight: normal;
    margin: 10px 0px;
  }
  .block .item-two p,
  .item-two ul li {
    font-size: 18px;
  }
  .section .block-image img {
    width: 100%;
    height: 25vh;
  }

  .section.second .item-one {
    width: 100%;
  }
  .section.second .item-two {
    width: 100%;
    justify-content: center;
  }
  .section.second .leftpos {
    display: flex;
    justify-content: flex-start;
  }

  .block .capacities {
    padding-left: 0px;
    width: 100%;
  }
  .block .capacities ul {
    padding-left: 0px;
  }

  .section.second h3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 25px;
  }
  .section ul {
    padding: 15px 0px;
  }
  .section.second ul li {
    display: list-item;
    list-style: disc;
    font-size: 16px;
    margin-left: 40px;
  }
  .infrastructure-image,
  .industries-image .container {
    display: flex;
    align-items: center;

    flex-direction: column;
  }
  .infrastructure-image .image {
    width: 95%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .infrastructure-image .image img {
    width: 90%;
  }

  .section.view .container {
    display: flex;
    gap: 3rem;
    justify-content: space-around;
    font-size: 18px;
  }

  .section.view h3 {
    font-size: 25px;
    font-weight: bold;
    font-family: "Helvetica 65 Medium", sans-serif;
    margin-right: 0;
    margin-left: 0;
  }
  /* END OF SECTION */

  /* FOOTER */
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }
  .footer p {
    padding-left: 0rem;
  }

  /* END OF FOOTER */
}
