@font-face {
  font-family: "67condensed";
  src: url(../../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.body {
  position: relative;
  min-height: 100%;
  padding-bottom: 12rem;
  background-image: url("../../../images/grey_background.png");
  background-size: contain;

}
.main {
  min-height: 100vh;
}

/* hamburger */

/* END OF TOP AND LEFT NAV */

/* CONTAINERS */
.mainContainer {
  width: calc(100% - 5.3%);
  height: auto;
  margin-right: 0;
  margin-left: auto;
  padding-top: 4rem;
}
.loadingDiv {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-2 {
  width: 65%;
  height: auto;
  margin: auto;
}

/* END OF CONTAINERS */

/*MAIN*/
.sectionOne {
  height: 100vh;
  padding-top: 7vh;
  margin-bottom: 5rem;
}

.main-header {
  border: 1px solid transparent;
  border-left: none;
  height: 100vh;
  background-size: cover;
  width: 100%;
  background-position-y: 30%;
  background-repeat: no-repeat;
  margin-bottom: 5rem;
}
.main-header h2 {
  font-size: 60px;
  font-weight: medium;
  margin-left: 80px;
  color: white;
  text-transform: uppercase;
  text-shadow: 5px 5px 12px black;

  font-family: "65medium", sans-serif;
  margin-top: 9rem;
  text-shadow: #874834;
}

.main-header h3 {
  color: white;
  text-shadow: 5px 5px 12px black;
  text-align: left;
  font-size: 35px;
  font-family: "55roman", sans-serif;
  font-weight: lighter;
  margin-left: 80px;
  text-transform: uppercase;
}
/* END OF MAIN */

/* SECTION */
.section {
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  width: calc(100% - 5.3%);
  margin-left: auto;
  margin-right: 0;
}
.section p {
  font-family: "45light", sans-serif;
  font-size: 22px;
  font-weight: lighter;
  margin-top: 50px;
  text-align: justify;
}
.section .socials {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  margin-top: 3rem;
}
.socials a {
  color: black;
  font-size: 24px;
}

.textBlock {
  margin-bottom: 3rem;
}
/* END OF SECTION */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ACACA9;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: white;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
/* END OF FOOTER */
@media screen and (max-width: 430px) {
  /* END OF LEFT AND TOP NAV */

  /* CONTAINERS */
  .mainContainer {
    width: 100vw;
    margin: auto;
  }
  .container-2 {
    width: 75%;
  }
  /* END OF CONTAINERS */

  /* MAIN */

  /*MAIN*/
  .sectionOne {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 30vh;
  }
  .main-header {
    height: 30vh;
    margin-bottom: 5rem;
  }
  .main-header h2 {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 20px;
  }
  .main-header h3 {
    font-size: 14px;
    margin-left: 20px;
  }

  .section {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
  }

  .section p {
    font-size: 16px;
    margin-top: 20px;
    text-align: left;
  }

  /* END OF MAIN */
  /* FOOTER */
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 88%;
    font-size: 11px;
  }

  /* END OF FOOTER */
}
