#references .carousel-cell p {
  position: relative;
}
#references {
  padding-bottom: 0;
}
#references .carousel {
  background: transparent;
  /* margin-top: 30px; */
  /* margin-bottom: 40px; */

  height: auto;
  margin-bottom: 0;
  padding-bottom: 0;
}
#references .carousel-main-2 .carousel-cell {
  width: 100%;
  height: 80vh;

  display: flex;
  justify-content: flex-start;
  padding-top: 8rem;
  align-items: center;
  flex-direction: column;
  border-radius: 0px;
  gap: 3rem;
  /* background-image: url("../../../images/PrimeTech_01.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
}
.carousel-main-2 .carousel-cell .titleBox {
  text-align: center;
}
/* #references .carousel-cell:before {
  content: "";
  
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.2);
} */
#references .carousel-main-2 .carousel-cell h1 {
  position: relative;
  font-size: 8.5vh;
  max-width: 60vw;
  font-family: "55roman", sans-serif;
}
/* #references  .carousel-cell div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
} */

#references .carousel-main-2 .carousel-cell a {
  position: relative;
  margin: 0;
  padding: 0;
  color: white;
  display: flex;
  align-items: center;
}

#references .ref-nav .flickity-page-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 85px;
}
#references .flickity-page-dots .dot {
  margin: 0;
  border-radius: 0;
  background-color: white;
  opacity: 0;
}
#references .flickity-page-dots .dot.is-selected {
  height: 8.5px;
  position: absolute;
  transition: all ease-in-out 0.7s;
  background-color: white;
  opacity: 1;
  width: 180px;
}
#references .flickity-button {
  display: none;
}
#references .ref-nav {
  position: relative;
  border-top: 1px solid lightgrey;
  bottom: 110px;
}
#references .ref-nav .carousel-cell {
  height: 70px;

  text-align: center;
  border-left: 2px solid white;
  margin: 0;
  padding: 0;
  border-radius: 0;
  background: none;
  margin-top: 10px;
  color: white;
  cursor: pointer;
}
.ref-nav .carousel-cell:nth-last-child(2) {
  /* border-right: 2px solid white; */
}
.ref-nav .carousel-cell .navCell {
  margin: 0 10%;
}
#references .ref-nav .carousel-cell p {
  padding-top: 10px;
  font-size: 1.2vw;
}
#references .ref-nav .carousel-cell:before {
  background-color: rgba(0, 0, 0, 0);
}
/* section#references .button a {
  border: 1px solid black;
  color: black;
} */
/* .ref-nav .carousel-cell.is-selected .navCell p {
  color: red;
} */
/* section#references .button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
} */

.loadingDiv {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
}
#references .moreBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
}
.moreBtn .span {
  font-size: 20px;
  height: 20px  ;
  color: #844939;
  /* padding-top: 3px; */
  font-weight: 500;
}
@media screen and (max-width: 800px) {
  #references .carousel-main-2 .carousel-cell h1 {
    position: relative;
    font-size: 6.5vh;
    max-width: 600px;
    font-family: "55roman", sans-serif;
  }
  #references .ref-nav .carousel-cell p {
    font-size: 14px;
  }
  #references .moreProjects {
    transform: translateY(-60px);
  }
  #references .moreBtn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
  }
}
