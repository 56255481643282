@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  padding-bottom: 7rem;
  padding-top: 5rem;
  min-height: 100%;
  background-image: url("../../images/light_background.png");
  background-size: contain;
}
.main {
  display: flex;
  justify-content: center;
  margin-bottom: 12rem;
  min-height: 100vh;
  flex-wrap: wrap;
  /* margin-bottom: 250px; */
}
.mainBanner {
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;
}
.container {
  width: 70vw;
  height: auto;
  margin: auto;
}

.blockContainer {
  width: 72%;
  height: auto;
  margin: auto;
  margin-bottom: 5rem;
  padding-top: 4rem;
}
.container-2 {
  padding-left: 70px;
  box-sizing: border-box;
  width: 100%;
}
.sectionOne {
  width: calc(100% - 5.3%);

  margin-right: 0;
  margin-left: auto;
}
.main h1 {
  margin-top: 10rem;
  font-size: 75px;
  font-weight: medium;
  margin-bottom: 3rem;
  color: black;
  font-family: "65medium", sans-serif;
  /* margin-left: 7rem; */
}

.section {
  display: flex;
  justify-content: center;
  font-family: "55roman", sans-serif;
  width: 100%;
  margin-top: 3rem;
}

.section ul {
  padding: 30px 0px;
  border-bottom: 1px solid #999999;
  display: flex;
  justify-content: space-between;
}
.section #section-nav {
  margin-bottom: 7rem;
}
.section #section-nav ul li {
  display: inline;
  font-family: "Helvetica 65 Medium", sans-serif;
  font-size: 14px;
  text-align: center;
}
.section #section-nav ul li a {
  color: #9a999a;
  text-decoration: none;
  text-transform: uppercase;
}
.section #section-nav ul li a:hover {
  color: black;
}

.cultureText {
  font-family: "Helvetica 55 Roman", sans-serif;
}
.cultureText h1 {
  font-size: 65px;
  color: #844939;
  margin-bottom: 2rem;
  font-weight: normal;
}
.cultureText p {
  opacity: 0.7;
}
.cultureText p,
.cultureText div {
  font-size: 28px;
  text-align: justify;
  margin-bottom: 1rem;
  font-family: "65medium", sans-serif;
  font-weight: 400;
  color: black;
}
.imageDiv {
  margin-top: 3rem;
}
.section.view .container {
  display: flex;
  gap: 7rem;
}
.section.view a {
  color: black;
}
.section.view h3 {
  font-size: 50px;
  font-family: "Helvetica 65 Medium", sans-serif;
  margin-right: 40px;
}

.loading {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
/* END OF FOOTER */
@media screen and (max-width: 430px) {
  .main-header {
    margin-top: 50px;
  }
  .main h1 {
    font-size: 30px;
    font-weight: medium;
    margin-top: 0;
    margin-bottom: 30px;
    color: black;
  }
  .container {
    width: 75%;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .section {
    margin-top: 0rem;
  }

  .sectionOne {
    margin-top: 3rem;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .sectionOne h1 {
    font-size: 3vh;
    font-weight: medium;
    margin-bottom: 2rem;
    margin-left: 0;
  }

  .mainBanner {
    margin-bottom: 5rem;
    height: 25vh;
    background-image: url("../../images/culture-images/cultureImg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    width: 100%;
  }
  .section #section-nav {
    display: none;
  }

  .section #section-nav ul {
    display: flex;
    flex-wrap: wrap;
    gap: 2;
  }

  .section #section-nav ul li {
    display: inline;
    font-size: 10px;
    text-align: left;
    margin-top: 10px;
  }

  /* .mini-navbar ul {
    display: flex;
    justify-content: space-around;
    font-size: 8px;
    font-weight: lighter;
  } */
  /* .mini-navbar ul li {
    display: inline;
    margin-right: 0px;
  } */
  .blockContainer {
    width: 75%;
    height: auto;
    margin: auto;
    margin-bottom: 0;
    padding-top: 0;
  }
  .cultureText h1 {
    font-size: 35px;
    word-wrap: break-word;
  }
  .cultureText p,
  .cultureText div {
    font-size: 16px;
    font-weight: 100;
  }
  .imageDiv {
    margin-top: 2rem;
  }
  .imageDiv img {
    height: 25vh;
  }

  .items {
    display: flex;
    flex-direction: column;
  }
  .item {
    width: 100%;
  }
  .section.view .container {
    gap: 0;
  }
  .section.view .container h3 {
    font-size: 25px;
  }

  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }
}
