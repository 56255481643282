@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "65medium", sans-serif;
}
.body {
  position: relative;
  min-height: 100%;
  padding-bottom: 10rem;
  background-image: url("../../images/light_background.png");
  background-size: contain;
  transition: all ease 0.3s;
}
.main {
  min-height: 100vh;
}
.image-size {
  width: 100%;
  height: 75vh;
  object-fit: cover;
  object-position: center;
  margin: 30px 0px 5rem 0;
}
.sectionOne .container-2 {
  margin-top: 100px;
}
.sectionOne,
.civil-images {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container {
  width: 72%;
  height: auto;
}
.segContainer {
  width: 72%;
  height: auto;
  padding-top: 5rem;
  margin-bottom: 8rem;
}
.main-header {
  width: 100%;
  padding-top: 70px;
}
.main-header h1 {
  font-size: 65px;
  font-weight: medium;
  margin-bottom: 30px;
  color: black;
  font-family: "65medium", sans-serif;
}
.sectionOne h3 {
  font-size: 35px;
  font-family: "Helvetica 65 Medium", sans-serif;
  text-align: justify;
}

.section {
  display: flex;
  justify-content: center;
  font-family: "55roman", sans-serif;
}

.section #section-nav .navFlex {
  padding: 6rem 0px 40px 0;
  border-bottom: 1px solid #999999;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.section #section-nav .navFlex span {
  /* margin-right: 10px; */
  font-size: 12px;
  /* width: 170px; */
  flex: 0 0 auto;
  width: fit-content;
  font-family: "Helvetica 65 Medium", sans-serif;
  max-width: 18%;

  /* text-align: left; */
}
.section #section-nav .navFlex span a {
  color: #9a999a;
  text-decoration: none;
  text-transform: uppercase;
  width: 10;
}
.section #section-nav .navFlex span a:hover {
  color: black;
}
.section.second {
  font-size: 20px;
  margin-top: 50px;
  flex-wrap: wrap;
  font-family: "55roman", sans-serif;
}
.section.second .items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.section.second h6 {
  width: 100%;
  font-size: 18px;
  font-weight: lighter;
  margin-bottom: 5px;
}

.section.second .item-two {
  width: 50%;
}
.section.second .item-one {
  width: 45%;
}
.section.second p {
  font-family: "65medium", sans-serif;
  margin-bottom: 25px;

  font-weight: 400;
  color: black;
  opacity: 0.7;

  font-size: 24px;
  text-align: justify;
}

.section.second h3 {
  font-size: 60px;
  color: #8c4a37;
  font-weight: 700;
  font-family: "Helvetica 55 Roman", sans-serif;
}
.section.second li {
  font-family: "65medium", sans-serif;
  font-size: 24px;
  margin-left: 20px;
  font-weight: 400;
  color: black;
  opacity: 0.7;
}
.section.second img {
  width: 100%;
}
.container-2 {
  width: calc(100% - 5.3%);
  margin-left: auto;
  margin-right: 0;
}
.slickSlider {
  margin-top: 5rem;
}
.loading {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section.view {
  margin-top: 2rem;
  margin-bottom: 200px;
}
.section.view .container {
  display: flex;
  gap: 7rem;
}
.section.view a {
  color: black;
}
.section.view h3 {
  font-size: 50px;
  font-family: "Helvetica 65 Medium", sans-serif;
  margin-right: 40px;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);

  /* background-image: url("../../images/dark_background.png"); */
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* END OF FOOTER */

@media screen and (max-width: 430px) {
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .container {
    width: 70%;
  }

  .container-2 {
    padding-left: 0;
    width: 100vw;
  }
  .image-size {
    height: 25vh;
  }
  .sectionOne h3 {
    font-size: 20px;
    text-align: justify;
  }
  #section-nav {
    display: none;
  }
  .section.second .container .items {
    flex-direction: column;
  }
  .section.second h2 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .section.second .container .item-one,
  .section.second .container .item-two {
    width: auto;
  }
  .section.second p,
  .section.second li {
    font-size: 18px;
  }

  /* recently added styling */
  .main {
    width: 100%;
  }
  .section.second .item-one {
    width: 100%;
  }
  .section.second .item-two {
    width: 100%;
  }

  .section.second h3 {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 25px;
  }

  .textBlock {
    width: 100%;
  }
  .section img {
    width: 100%;
    height: 25vh;
  }

  .segContainer {
    margin-bottom: 0;
  }

  .section.view .container {
    display: flex;
    gap: 3rem;
  }

  .section.view h3 {
    font-size: 25px;
    font-weight: bold;
    font-family: "Helvetica 65 Medium", sans-serif;
    margin-right: 0;
  }

  /* end of recently added */

  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    /* width: 88%; */
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }
}
