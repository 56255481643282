@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  text-decoration: none;
}

/* CONTAINERS */
.container {
  width: 75%;
  height: auto;
  margin: auto;
}
.container-2 {
  margin-left: auto;
  margin-right: 0;
  width: calc(100% - 5.3%);
}
.body {
  position: relative;
  padding-bottom: 5rem;
  min-height: 100%;
  background-image: url("../../images/grey_background.png");
  background-size: contain;
  padding-top: 8rem;
  /* background-position: cover;
  background-repeat: no-repeat; */
}
.main {
  /* margin-bottom: 12rem; */
  min-height: 100vh;

  /* margin-bottom: 250px; */
}

/* END OF CONTAINERS */

/*MAIN*/
.firstSection {
  display: flex;

  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.front-cover {
  width: 100%;
  /* margin-top: 12rem; */
  margin-bottom: 50px;
}
.image-size {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  object-position: center;
}
.main-header {
  width: 100%;
  margin: 70px 0px;
  /* position: absolute; */
}

.firstSection h3 {
  text-align: left;
  font-size: 35px;
  font-family: "Helvetica 55 Roman", sans-serif;
  /* line-height: 43px; */
  /* position: absolute;
  z-index: 1; */
  /* padding-right: 300px; */
  width: auto;
}

/* END OF MAIN */
/* SECTION */
.section {
  display: flex;
  justify-content: center;
  font-family: "55roman" sans-serif;
  margin: 5rem 0;
}

.segItem {
  width: 56%;
  font-family: "Helvetica 55 Roman", sans-serif;
  /* height: 20%;
  width: 50%; */
  height: 10vh;
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.section .segItem p {
  font-size: 18px;
  color: #844939;
}
.section .segItem h2 {
  font-family: "65medium", sans-serif;
  font-size: 4vh;
  width: 88%;
  padding-top: 0;
  margin-top: 0;
  transform: translateY(-5px);
  font-weight: 400;
  color: black;
  opacity: 0.7;
}

.segItems {
  display: flex;
  flex-direction: column;
  column-gap: 2rem;
  flex-wrap: wrap;
  margin-bottom: 30px;
  height: 30vh;
  margin-top: 2rem;
}
.section .item-one {
  width: 45%;
  text-align: justify;
  font-family: "Helvetica 55 Roman", sans-serif;
}
.section .item-two {
  width: 50%;
}
.section p {
  font-size: 24px;
  margin-bottom: 40px;
  font-family: "65medium", sans-serif;
  font-weight: 400;
  color: black;
  opacity: 0.7;
}
.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.people {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
  margin-bottom: 30px;
  column-gap: 2rem;
}

.item {
  margin-bottom: 30px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.item .profileImage {
  height: 40vh;
  /* background-image: url("../../images/PrimeTech_01.jpg"); */
  background-position: center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;
}
.item a p {
  color: white;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 15px 12px;
}
.section h1 {
  font-size: 65px;
  margin: 5rem 0;
}
.section.our_people h2 {
  text-align: center;
  font-size: 60px;
  font-family: "65medium", sans-serif;
  margin: 30px;
}
.section.our_people h6 {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 30px;
  margin-bottom: 4rem;
}

.section ol li {
  margin-left: 20px;
  font-size: 12px;
  color: #874834;

  padding: 20px;
  box-sizing: border-box;
}
.section ol li span {
  color: black;
  font-size: 24px;
}
.loading {
  width: 100%;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #acaca9;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: white;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

/* END OF FOOTER */
@media screen and (max-width: 500px) {
  /* CONTAINERS */
  .container {
    width: 75%;
  }
  .container-2 {
    padding-left: 0;
    width: 100%;
  }
  .section {
    margin: 3rem 0;
  }
  .section .items .item-one p {
    width: 100%;
  }
  .image-size {
    height: 25vh;
  }
  /* END OF CONTAINERS */

  /* MAIN */
  .main-header {
    margin: 0px 0px;
  }
  .main h4 {
    font-size: 15px;
    text-align: center;
    padding-right: 0;
    text-align: left;
  }

  .firstSection h3 {
    text-align: left;
    font-size: 20px;
    /* line-height: 20px; */
    font-weight: 800;
    /* position: absolute;
    z-index: 1; */
    /* padding-right: 300px; */
    width: auto;
  }

  /* END OF MAIN */
  .items {
    display: flex;
    flex-direction: column;
  }
  .section .item-one,
  .section .item-two {
    width: 100%;
  }

  .section p {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .section.our_people h2 {
    font-size: 40px;
    margin: 0;
  }
  .section.our_people h6 {
    font-size: 15px;
    font-weight: 200;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .item .profileImage {
    height: 15vh;
    width: 100%;
  }
  .item a p {
    font-size: 13px;
    font-weight: normal;
    padding: 2px 4px;
  }

  section.our_people .items {
    display: flex;
    align-items: center;
  }

  .section h1 {
    font-size: 35px;
    margin: 0rem 0;
  }

  .segItems {
    display: flex;
    flex-direction: column;
    column-gap: 1.5rem;
    margin-bottom: 60px;
    margin-top: 1rem;
    height: auto;
  }

  .segItem {
    width: 100%;
    font-family: "Helvetica 55 Roman", sans-serif;
    height: auto;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  .section .segItem p {
    font-size: 2vh;
    color: #844939;
  }
  .section .segItem h2 {
    font-family: "Helvetica 55 Roman", sans-serif;
    font-size: 3vh;
    font-weight: lighter;
    width: 100%;
    padding-top: 0;
    margin-top: 0;
    transform: translateY(0px);
  }

  /* FOOTER */
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding: 0.5rem 1rem;
  }
  .footer p {
    padding-left: 0rem;
  }

  /* END OF FOOTER */
}
