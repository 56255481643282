@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.container {
  width: 65%;
  height: auto;
  margin: auto;
}
.body {
  position: relative;
  min-height: 100%;
  padding-bottom: 10rem;
  background-image: url("../../images/light_background.png");
  background-size: contain;
}
.main {
  min-height: 100vh;
}
.main-header {
  padding-top: 8rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.main-header button {
  display: none;
  align-self: flex-end;
  padding: 7px 35px;
  border-radius: 10px;
  border: 1.5px solid black;
  background-color: white;
}
.main-header h1 {
  line-height: 1;
  font-size: 7.8vh;
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 30px;
  color: black;
  font-family: "65medium", sans-serif;
  width: 60%;
}
.segment h3 {
  font-size: 14px;
  font-weight: lighter;
  font-family: "65medium", sans-serif;
}
.segment {
  margin: 2rem 0;
}
.segment p {
  font-weight: 100;
}
.text p,
.text ul {
  font-size: 1.3rem;
  width: 100%;

  font-family: "45light", sans-serif;
  text-align: justify;
  margin: 1rem 0 2.5rem;
}
.text ul li {
  font-family: "45light", sans-serif;
  line-height: 2rem;
}
.text h3 {
  font-size: 1.8rem;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: #844939;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

@media screen and (max-width: 500px) {
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 25px;
    font-weight: medium;
    margin-bottom: 30px;
    color: black;
  }
  .text h3 {
    font-size: 25px;
  }
  .text ul {
    width: 100%;
    padding-left: 20px;
  }
  .container {
    width: 72%;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

}
