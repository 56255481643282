@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "65medium", sans-serif;
  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  padding-bottom: 15rem;
  min-height: 100%;
  background-image: url("../../images/dark_background.png");
  background-size: contain;
  /* background-position: cover;
    background-repeat: no-repeat; */
}
.main {
  /* margin-bottom: 12rem; */
  min-height: 100vh;

  /* margin-bottom: 250px; */
}
.container {
  width: 70vw;
  height: auto;
  margin: auto;
  /* border: 1px solid lightgreen; */
}
.container-2 {
  padding-left: 70px;
  box-sizing: border-box;
  width: 100%;
}
.mini-navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 8rem;
}
.mini-navbar li {
  text-decoration: none;
  color: gray;
}
.mini-navbar a:hover {
  color: white;
}
.mini-navbar ul {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 12px;
}
.mini-navbar ul li {
  display: inline;
  /* margin-right: 50px; */
  text-transform: uppercase;
  padding: 0 0 3px 40px;
  text-align: right;
  margin-top: 1rem
}

.main-header {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 7rem;
}
.main-header h1 {
  font-size: 65px;
  font-weight: medium;
  margin-bottom: 40px;
  color: white;
  font-family: "65medium", sans-serif;
}
.active {
  color: white;
  border-bottom: 3px solid #844939;
  padding-bottom: 3px;
}
.items {
  font-family: "55roman", sans-serif;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20vw, 1fr));
  column-gap: 2rem;
}
.items a {
  color: red;
}
.item {
  width: 100%;
  height: 35vh;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.item .refBox {
  height: 30vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
  background-size: cover;
}

/* .item .refBox:hover {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.8),
    url("../../images/PrimeTech_01.jpg")
  );
} */

.refBox:hover p {
  animation: movement 0.7s forwards;
}
@keyframes movement {
  100% {
    transform: translateY(0px);
    opacity: 1;
  }

  0% {
    opacity: 0;
  }
}
.item span {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item span:hover {
  background-image: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8));
}
.item p {
  font-size: 28px;
  width: 70%;
  text-align: center;
  color: white;
  transform: translateY(25px);
  opacity: 0;
  /* height: 100%;
  width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.noNews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
}
/* FOOTER */

.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid #9a999a;

  /* background-image: url("../../images/dark_background.png"); */
  background-color: black;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: white;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}
.footer .moreInfo a:hover {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

/* END OF FOOTER */
@media screen and (max-width: 430px) {
  .main-header {
    margin-top: 50px;
  }
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
    margin-bottom: 30px;
    /* color: black; */
  }
  .container {
    width: 80%;
  }
  .container-2 {
    padding-left: 0;
    width: 90%;
  }
  .mini-navbar ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 8px;
    font-weight: lighter;
  }

  .mini-navbar ul li {
    display: inline;
    margin-right: 0px;
    padding: 0;
    margin-top: .5rem;
  }
  .items {
    display: flex;
    flex-direction: column;
  }
  .item {
    width: 100%;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

}
