@font-face {
  font-family: "67condensed";
  src: url(../../../Fonts/67-condensed.otf);
}
@font-face {
  font-family: "65medium";
  src: url(../../../Fonts/65-medium.otf);
}
@font-face {
  font-family: "55roman";
  src: url(../../../Fonts/55-roman.otf);
}
@font-face {
  font-family: "55romanitalic";
  src: url(../../../Fonts/55-roman-italic.otf);
}
@font-face {
  font-family: "45light";
  src: url(../../../Fonts/45-light.otf);
}

* {
  margin: 0;
  padding: 0;

  scroll-behavior: smooth;
  text-decoration: none;
}
.body {
  position: relative;
  padding-bottom: 15rem;
  min-height: 100%;
  background-image: url("../../images/dark_background.png");
  background-size: contain;
  /* background-position: cover;
      background-repeat: no-repeat; */
}
.main {
  /* margin-bottom: 12rem; */
  min-height: 100vh;

  /* margin-bottom: 250px; */
}
.container {
  width: 70%;
  height: auto;
  margin: auto;
}
.slideshowContainer {
  width: 85%;
  height: auto;
  margin-right: 0;
  margin-left: auto;
}
.container-2 {
  padding-left: 70px;
  box-sizing: border-box;
  width: 100%;
}
.mini-navbar {
  width: 100%;
  padding-top: 8rem;
  text-align: right;
}

.mini-navbar a {
  color: white;
}

.main-header {
  width: 100%;
  margin-top: 70px;
  margin-bottom: 5rem;
}
.main-header h1 {
  font-size: 65px;
  font-weight: medium;
  color: white;
  font-family: "65medium", sans-serif;
}
.noNews {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40vh;
}
.projectDetails {
  border-bottom: 1px solid white;
  margin: 45px 0 15px 0;
}
.projectDetails .firstRow {
  display: flex;
  align-items: flex-start;
  justify-content: start;
  gap: 5rem;
  margin-bottom: 4rem;
}
.firstRow h3,
.projectDetails h3 {
  font-family: "Helvetica 55 Roman", sans-serif;

  font-size: 20px;
  margin-bottom: 10px;
  color: white;
}
.firstRow p {
  font-family: "Helvetica 45 Light", sans-serif;
  font-size: 18px;
  color: white;
  text-transform: capitalize;
}
.content p {
  font-family: "Helvetica 45 Light", sans-serif;
  font-size: 18px;
  color: white;
  font-weight: normal;
  text-align: justify;
}
.footer {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #844939;
  border-top: 1px solid #9a999a;

  /* background-image: url("../../images/dark_background.png"); */
  background-color: black;
  background-size: 100%;
  font-family: "55roman", sans-serif;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 5.3%);
  margin-right: 0;
  margin-left: auto;
}
.footer .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 2rem;
  box-sizing: border-box;
}
.footer a {
  color: #999999;
  text-decoration: none;
  font-family: "45light", sans-serif;
}
.footer p {
  color: white;
}
.footer .more-info {
  width: 50%;
}
.footer ul li {
  display: inline;
  margin-right: 15px;
}

@media screen and (max-width: 430px) {
  .main-header h1 {
    font-size: 30px;
    font-weight: medium;
  }
  .mini-navbar a {
    font-size: 12px
  }
  .projectDetails {
    border-bottom: 1px solid white;
    margin: 45px 0 15px 0;
  }
  .projectDetails .firstRow {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 4rem;
    flex-wrap: wrap;
  }
  .firstRow h3,
  .projectDetails h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .firstRow p {
    font-size: 14px;
  }
  .content p {
    font-size: 16px;
  }
  .footer ul li {
    display: block;
    margin: 5px 0px;
  }
  .footer .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: auto;
    box-sizing: border-box;
    width: 95vw;
    font-size: 11px;
    padding:0.5rem 1rem;
  }
  .footer p{
    padding-left: 0rem;
  }

}
